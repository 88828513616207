
















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RoutePrint extends Vue {
  @Prop() route: any

  get routeName() {
    return this.route.name
  }

  get destinations() {
    return this.route.destinations.map((dest) => {
      dest.orders = dest.items
        .sort((item) => item.stockItem.productId)
        .sort((item) => item.stockItem.stockPositionId)
        .sort((item) => item.stockItem.orderId)
        .reduce((products, { stockItem: item }, index, array) => {
          const hasOrderInList = products.findIndex((entry) => entry.orderId === item.orderId)
          const hasProductInList = products.findIndex(
            (entry) => entry.productId === item.productId && entry.orderId === item.orderId,
          )
          const hasProductWithSameStockPosInList = products.findIndex(
            (entry) =>
              entry.productId === item.productId &&
              entry.orderId === item.orderId &&
              entry.stockPositionId === item.stockPosition.id,
          )

          if (hasProductWithSameStockPosInList === -1) {
            products.push({
              orderId: item.orderId,
              orderRef: item.orderProduct.order.customerReference,
              productId: item.productId,
              productName: item.orderProduct.productName,
              stockPosition: item.stockPosition.name,
              stockPositionId: item.stockPosition.id,
              quantity: 1,
              orderSpan: hasOrderInList === -1 ? 1 : 0,
              productSpan: hasProductInList === -1 ? 1 : 0,
            })
            if (hasOrderInList !== -1) {
              products[hasOrderInList].orderSpan++
            }
            if (hasProductInList !== -1) {
              products[hasProductInList].productSpan++
            }
          } else {
            products[hasProductWithSameStockPosInList].quantity++
          }

          return products
        }, [])
      return dest
    })
  }
}
