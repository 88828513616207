import buildUrl from 'build-url'
import { GoogleMaps, Position } from '@/types/GoogleMaps'

const CALLBACK_NAME = '__googleMapsApiOnLoadCallback'

class MapsService {
  maps: GoogleMaps
  geocoder: google.maps.Geocoder = null

  constructor(maps) {
    this.maps = maps
    // console.log(maps)
  }

  getDirectionsRoute(
    origin: Position,
    destination: Position,
    waypoints: google.maps.DirectionsWaypoint[] | null = null,
  ): Promise<google.maps.DirectionsResult> {
    const service = new this.maps.DirectionsService()
    return new Promise((resolve, reject) =>
      service.route(
        {
          origin,
          destination,
          travelMode: google.maps.TravelMode.DRIVING,
          optimizeWaypoints: waypoints.length > 0,
          ...(waypoints.length > 0 ? { waypoints } : {}),
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(result)
          } else {
            /* eslint-disable-next-line */
						reject({ result, status })
          }
        },
      ),
    )
  }

  sortArrayAccordingToDistance<T = any>(
    origin: Position,
    array: T[],
    extractWaypoint: (T) => google.maps.DirectionsWaypoint,
  ): PromiseLike<T[]> {
    const service = new this.maps.DirectionsService()
    return new Promise((resolve, reject) => {
      service.route(
        {
          origin,
          destination: origin,
          travelMode: google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
          waypoints: array.map(extractWaypoint),
        },
        (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(result)
          } else {
            /* eslint-disable-next-line */
						reject({ result, status })
          }
        },
      )
    }).then((result: google.maps.DirectionsResult) => {
      const route = result.routes[0]
      return route.waypoint_order.map((index) => array[index])
    })
  }

  normalizeAddress(address: string) {
    if (this.geocoder === null) {
      this.geocoder = new this.maps.Geocoder()
    }
    return new Promise((resolve, reject) => {
      this.geocoder.geocode(
        {
          address,
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve({ results, status })
          } else {
            reject({ results, status })
          }
        },
      )
    })
  }
}

const promise = new Promise((resolve, reject) => {
  if (window.google !== undefined) {
    resolve(window.google)
    return
  }
  const timeoutId = setTimeout(function () {
    window[CALLBACK_NAME] = function () {}
    reject(new Error('Could not load the Google Maps API'))
  }, 10000)

  window[CALLBACK_NAME] = function () {
    clearTimeout(timeoutId)
    resolve(window.google)
    delete window[CALLBACK_NAME]
  }

  const scriptElement = document.createElement('script')
  scriptElement.src = buildUrl('https://maps.googleapis.com/maps/api/js', {
    queryParams: {
      callback: CALLBACK_NAME,
      key: 'AIzaSyDKkX6DMgiQRIdMGncF2CfalK0OoXLXG5o',
    },
  })

  document.body.appendChild(scriptElement)
})

export default promise.then(({ maps }: any) => new MapsService(maps))
