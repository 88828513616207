





























































  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
  import printJS from 'print-js-updated'
  import RoutePrint from '@/components/logistics/RoutePrint.vue'
  import { TableHeader } from '@/types/Vuetify'
  import GoogleMaps from '@/components/maps/GoogleMaps.vue'

  @Component({
    components: { GoogleMaps, RoutePrint },
  })
  export default class RouteDetail extends Vue {
    @Prop() route: any
    @Model('change') readonly dialog!: boolean

    panels = []

    expanded = []

    destinationsHeaders: TableHeader[] = [
      {
        text: '#',
        value: 'index.ts.ts.ts.ts.ts',
      },
      {
        text: 'Delivery name',
        value: 'deliveryName',
      },
      {
        text: 'Delivery address',
        value: 'deliveryAddress',
      },
      {
        text: '',
        value: '',
        sortable: false,
      },
    ]

    itemsHeaders: TableHeader[] = [
      {
        text: 'Order id',
        value: 'orderId',
      },
      {
        text: 'Product name',
        value: 'productName',
      },
      {
        text: 'Quantity',
        value: 'quantity',
      },
      {
        text: 'Position(s)',
        sortable: false,
        value: 'positions',
      },
    ]

    get passThroughDialog(): boolean {
      return this.dialog
    }

    set passThroughDialog(value) {
      this.$emit('change', value)
    }

    get routeName() {
      return this.route && this.route.name
    }

    get destinations() {
      return ((this.route && this.route.destinations) || []).map((destination, index) => ({
        ...destination,
        index: index + 1,
      }))
    }

    get bounds() {
      if (this.route !== null) {
        return this.route.route.routes[0].bounds
      }
      return null
    }

    get points(): google.maps.MarkerOptions[] {
      let result = []
      if (this.route !== null) {
        const route = this.route.route.routes[0]
        const hub = route.legs[0].start_location
        result = [
          {
            position: hub,
            title: 'Start',
          },
        ]
        result.push(
          ...route.legs
            .slice(1, route.legs.length - 1)
            .map((leg, index) => {
              return {
                tmp: leg.end_location,
                position: leg.end_location,
                title: leg.end_address,
                label: `${index + 1}`,
              }
            })
            // .filter(opts => opts.tmp !== hub)
            .map(({ tmp, ...opts }, index) => opts),
        )
      }
      return result
    }

    get routePath() {
      if (this.route !== null) {
        return this.route.route.routes[0].legs.flatMap((leg) => {
          return leg.steps.flatMap((step) => step.path.flatMap((latLng) => latLng))
        })
      }
      return []
    }

    groupItems(items: any[]) {
      return items
        .reduce((items, item) => {
          const index = items.findIndex(({ productId }) => productId === item.stockItem.productId)
          if (index >= 0) {
            let positions = items[index].positions
            if (!positions.includes(item.stockItem.stockPosition.name)) {
              positions = [...positions, item.stockItem.stockPosition.name]
            }
            items[index] = {
              ...items[index],
              quantity: items[index].quantity + 1,
              positions: positions,
            }
          } else {
            items = [
              ...items,
              {
                ...item,
                orderId: item.stockItem.orderId,
                orderRef: item.stockItem.orderProduct.order.customerReference,
                productId: item.stockItem.productId,
                productName: item.stockItem.orderProduct.productName,
                quantity: 1,
                positions: [item.stockItem.stockPosition.name],
              },
            ]
          }
          return items
        }, [])
        .map((item) => {
          item.positions = item.positions.join(', ')
          return item
        })
    }

    printRoute() {
      const el = new Vue({
        render: (h) =>
          h(RoutePrint, {
            props: {
              route: this.route,
            },
          }),
      }).$mount()
      const style = `
			.destination {
				margin:20px 0;
			}
			h2 {
				margin-bottom: 10px;
			}
			td {
				padding: 5px;
			}
			table, td, th {
				border: 1px solid black;
			}
			table{
				margin-top: 10px;
				width:100%;
  				border-collapse: collapse;
			}
			.position {
				width:110px;
			}
			.quantity {
				width:90px;
			}
			`
      // @ts-ignore
      printJS({ type: 'raw-html', documentTitle: 'Route details', printable: el.$el.innerHTML, style })
      el.$destroy()
    }

    @Watch('dialog')
    onDialogChanged(value, oldValue) {
      if (!value && oldValue) {
        this.panels = []
      }
    }
  }
