










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MapsService from '@/services/MapsService'

const hashString = (str: string) => {
  let hash = 0
  if (str.length === 0) return hash
  const length = str.length
  for (let i = 0; i < length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0
  }
  return hash
}

@Component
export default class GoogleMaps extends Vue {
  @Prop() paths: any[]
  @Prop() bounds: google.maps.LatLngBoundsLiteral
  @Prop() markers: google.maps.MarkerOptions[]

  map: google.maps.Map | null = null
  path: google.maps.Polyline | null = null
  mapMarkers: { [key: number]: google.maps.Marker } = {}

  get staticImg() {
    return `https://maps.googleapis.com/maps/api/staticmap?center=Oslo,Norway&zoom=13&size=1200x500&maptype=roadmap&key=AIzaSyDKkX6DMgiQRIdMGncF2CfalK0OoXLXG5o`
  }

  mounted() {
    if (this.map === null) {
      this.loadMap()
    }
  }

  beforeDestroy() {
    this.map = null
  }

  loadMap() {
    this.$wait.start('loading.maps')
    MapsService.then((service) => {
      const maps = service.maps
      this.map = new maps.Map(this.$refs.map as Element, {
        center: new google.maps.LatLng(55.6145959, 13.0316161),
        zoom: 13,
      })
      this.onPathsChanged()
      this.onMarkersChanged()
      this.onBoundsChanged()
      this.$wait.end('loading.maps')
    })
  }

  @Watch('paths', { immediate: true, deep: true })
  onPathsChanged() {
    if (this.map === null) return

    if (this.path !== null) {
      this.path.setMap(null)
    }
    this.path = new google.maps.Polyline({
      path: this.paths[0],
      strokeColor: '#000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
    })
    this.path.setMap(this.map)
  }

  @Watch('markers', { immediate: true, deep: true })
  onMarkersChanged() {
    if (this.map === null) return
    if (this.mapMarkers === null) return

    const _markers = this.markers.map((opts) => ({
      hash: hashString(`${opts.position.lat},${opts.position.lng}`),
      opts,
    }))
    let toBeAdded = _markers
      .filter(({ hash }) => !this.mapMarkers.hasOwnProperty(hash))
      .reduce((acc, { hash, opts }) => {
        const marker = new google.maps.Marker(opts)
        return { ...acc, [hash]: marker }
      }, {})

    toBeAdded = _markers
      .filter(({ hash }) => !toBeAdded.hasOwnProperty(hash))
      .reduce((acc, { hash, opts }) => {
        const marker = this.mapMarkers[hash]
        marker.setOptions(opts)
        return { ...acc, [hash]: marker }
      }, toBeAdded)

    Object.entries(this.mapMarkers)
      .filter(([hash]) => !toBeAdded.hasOwnProperty(hash))
      .forEach(([hash, marker]) => {
        marker.setMap(null)
      })

    Object.values(toBeAdded).forEach((marker: google.maps.Marker) => marker.setMap(this.map))

    this.mapMarkers = toBeAdded
  }

  @Watch('bounds', { immediate: true, deep: true })
  onBoundsChanged() {
    if (this.map === null) return
    if (this.bounds === null) return

    const GLOBE_WIDTH = 256
    let angle = this.bounds.east - this.bounds.west
    if (angle < 0) {
      angle = this.bounds.south - this.bounds.north
    }
    setTimeout(() => {
      if (this.map === null) return

      const zoom = Math.round(Math.log((this.map.getDiv().clientWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2) - 1
      this.map.setZoom(zoom)
      this.map.panToBounds(this.bounds, 100)
    }, 500)
  }
}
