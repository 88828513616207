




























































import { Component, Vue } from 'vue-property-decorator'
import * as moment from 'moment'
import RouteDetail from '@/components/logistics/RouteDetail.vue'
import { TableHeader } from '@/types/Vuetify'

@Component({
  components: { RouteDetail },
})
export default class Admin extends Vue {
  headers: TableHeader[] = [
    {
      text: 'Route name',
      value: 'name',
    },
    {
      text: 'Stops',
      value: 'stops',
    },
    {
      text: 'Items',
      value: 'items',
    },
    {
      text: 'Duration',
      value: 'duration',
    },
    {
      text: 'Driver',
      value: 'driver',
    },
    {
      text: '',
      value: '',
      sortable: false,
    },
  ]

  routes: any[] = []
  dialog = false
  selectedRouteId: number = null

  prompt = false
  promptId: number = null

  get selectedRoute() {
    return this.routes.find(({ id }) => id === this.selectedRouteId) || null
  }

  mounted() {
    this.loadRoutes()
  }

  displayTripLength(seconds: number): string {
    const duration = moment.duration(seconds, 's')
    return `${duration.get('h')}:${duration.get('m')}`
  }

  selectRoute(routeId) {
    this.selectedRouteId = routeId
    this.dialog = true
  }

  deleteRoute(routeId, confirmed = false) {
    if (confirmed) {
      this.prompt = false
      this.promptId = null
      this.$axios.delete(`/v3/logistics/routes/${routeId}`).then(() => {
        this.loadRoutes()
      })
    } else {
      this.prompt = true
      this.promptId = routeId
    }
  }

  private async loadRoutes() {
    this.$wait.start('load.routes')
    this.routes = await this.$axios
      .get('/v3/logistics/routes')
      .then(({ data }) => data.data)
      .then((data) => {
        return data.map((route) => {
          const destinations = route.destinations.map((destination) => destination.items.length)
          const jsonBlob = typeof route.route === 'string' ? JSON.parse(route.route) : route.route
          let duration
          try {
            duration = jsonBlob.routes[0].legs.reduce((sum, leg) => sum + leg.duration.value + 300, -300)
          } catch (e) {
            duration = null
          }
          return {
            ...route,
            stops: destinations.length,
            items: destinations.reduce((acc, count) => acc + count, 0),
            duration,
            driver: route.driver && route.driver.name,
          }
        })
      })
    this.$wait.end('load.routes')
  }
}
